<div class="card"> 
  <div class="card-header">
    <div class="tabs is-medium">
      <ul>
        <li *ngFor="let tab of tabs" class="tab" [ngClass]="{'is-active': tab.active}"><a (click)="changeTab(tab)">{{tab.title}}</a></li>        
      </ul>
    </div>
  </div>  
  <div class="card-content">      
    <div class="content"> 
      
      <div class="info-header">
        <div class="title">
          {{selectedTab.title}}
        </div>
        <div class="subtitle">{{date.dayString}} {{date.dayNumber}} {{date.monthString}}</div>
      </div>
      
      <div class="title weather-value">{{selectedTab.weather.temp_c}}° C</div>      
      
      <div class="details level">
        <div class="level-left">
          <div class="level-item has-text-centered">
            <div>
              <p class="subtitle">Hum</p>
              <p class="title details-value">{{selectedTab.weather.hum}}%</p>
            </div>
          </div>
        </div>
        <div class="level-right">
          <div class="level-item has-text-centered">
            <div>
              <p class="subtitle">Wind</p>
              <p class="title details-value">{{selectedTab.weather.wind}} km/h</p>
            </div>
          </div>
        </div>        
      </div>
      
    </div>
  </div>
</div>
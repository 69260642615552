<div class="card">  
  <div class="card-content">    
    
    <div class="media">
      <div class="media-left">
        <figure class="image is-48x48">
          <img src="./../../assets/img/github.svg" alt="Placeholder image">
        </figure>
      </div>
      <div class="media-content">
        <p class="title is-5">Recently starred repos</p>
        <p class="subtitle is-6">
          <a class="github-link" href="https://github.com/christopherkade">@christopherkade</a>
        </p>
      </div>
    </div>
    
    <div class="content">
      <div *ngFor="let repo of repos">
        <div class="repo-title">          
          <a class="repo-link" [href]=repo.html_url>
            <div class="card repo-card">
              <div class="repo-name subtitle">
                {{repo.name}}
              </div>
              <div class="repo-description">
                {{repo.description}}
              </div>
            </div>
          </a>          
        </div>
      </div>
    </div>

  </div>
</div>
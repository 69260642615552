<div class="card">   
  <div class="card-content">      
    <div class="content"> 
      <div class="title learning-title">Currently working on...</div>      
      <div class="columns is-multiline">
        <div class="column is-6">
          <img class="learning-img" src="https://raw.githubusercontent.com/rexxars/react-hexagon/HEAD/logo/react-hexagon.png" alt="React">
        </div>
        <div class="column is-6">
          <img class="learning-img" src="https://nodejs.org/static/images/logo-hexagon.png" alt="Node">
        </div>
        
        <div class="column is-6">            
          <img class="learning-img" src="https://d2eip9sf3oo6c2.cloudfront.net/tags/images/000/000/300/full/angular2.png" alt="Angular">
        </div>
        <div class="column is-6">            
          <img class="learning-img" src="https://upload.wikimedia.org/wikipedia/commons/thumb/7/74/Kotlin-logo.svg/1024px-Kotlin-logo.svg.png" alt="Kotlin">
        </div>
      </div>    
    </div>
  </div>
</div>
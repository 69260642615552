<div class="card">
  <header class="card-header">
    <div class="card-header-title">Optimizing the performance of my personal website</div>
  </header>
  <div class="card-content">    
    <img src="./../../assets/img/rocket.png" alt="Rocket">
    <div class="content">
      <div class="post-description">
        My personal website has gone through many iterations of its front-end, more or less 4 of them so far (in less than a year). I find that rebuilding my website gives me a project outside of work to constantly work on, and allows me to apply acquired knowledge in a concrete manner. I’m also pretty picky when it comes to design, and I often get bored of the ones I’ve built in the past... <a class="post-link" href="https://blog.christopherkade.com/web/performance/2018/01/15/optimizing-website/">[Read more]</a>
      </div>        
    </div>
  </div>
</div>